<template>
  <v-container fluid class='align-self-sm-stretch justify-space-between'>
    <v-col class='pa-0 py-3'>
      <v-tooltip bottom>
        <template #activator='{ on }'>
          <v-icon v-on='on' @click='$emit("close")'>
            mdi-arrow-left-circle
          </v-icon>
        </template>
        {{ $t('BACK') }}
      </v-tooltip>
    </v-col>
    <v-col>
      <v-row>
        <span class='subtitle-2'>{{ $t('PERIOD') }}</span>
      </v-row>
      <v-row>
        <v-chip-group
          style='width: 100%'
          outlined
          column
          active-class='selected-chip'
          @change='changeDateFilter'
        >
          <v-chip
            v-for='filter in filters'
            :key='filter.text'
            outlined
            class='mr-5'
          >
            {{filter.text}}
          </v-chip>
        </v-chip-group>
      </v-row>
    </v-col>
    <v-col v-show='showFilters' cols='12'>
      <v-row class='mt-4 d-flex align-center'>
        <v-col cols='12'>
          <v-row class='d-flex align-center'>
            <span class='mr-5'>{{ $t('FROM') }}: </span>
            <CustomDatePicker @change='setStartDate' />
          </v-row>
        </v-col>
        <v-col cols='12'>
          <v-row class='d-flex align-center'>
            <span class='mr-5'>{{ $t('UNTIL') }}: </span>
            <CustomDatePicker @change='setEndDate' />
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col class='card-footer'>
      <v-btn
        color='secondary'
        dark
        @click='applyFilter'
      >
        {{ $t('SAVE') }}
      </v-btn>
    </v-col>
  </v-container>
</template>
<script>
  export default {
    components: {
      CustomDatePicker: () => import('../../components/DatePicker/CustomDatePicker.vue'),
    },
    data () {
      return {
        showFilters: false,
        startDateFromPicker: null,
        endDateFromPicker: null,
        startDate: null,
        endDate: null,
        showStartDatePicker: false,
        showEndDatePicker: false,
        filters: [
          {
            text: this.$t('TODAY'),
            value: 'today',
          },
          {
            text: this.$t('X_DAYS', { value: 3 }),
            value: '3days',
          },
          {
            text: this.$t('X_DAYS', { value: 7 }),
            value: '7days',
          },
          {
            text: this.$t('X_DAYS', { value: 15 }),
            value: '15days',
          },
          {
            text: this.$t('X_DAYS', { value: 30 }),
            value: '30days',
          },
          {
            text: this.$t('CUSTOM'),
            value: 'custom',
          },
        ],
      };
    },
    methods: {
      changeDateFilter (index) {
        const today = new Date();
        switch (this.filters[index].value) {
          case 'custom':
            this.showFilters = true;
            break;
          case 'today':
            this.showFilters = false;
            this.startDate = `${today.toISOString().split('T')[0]} 00:00:00`;
            this.endDate = `${today.toISOString().split('T')[0]} 23:59:59`;
            this.$emit('onFilter', { startDate: this.startDate, endDate: this.endDate });
            break;
          case '3days':
            this.showFilters = false;
            this.endDate = `${today.toISOString().split('T')[0]} 23:59:59`;
            today.setDate(today.getDate() - 3);
            this.startDate = `${today.toISOString().split('T')[0]} 00:00:00`;
            this.$emit('onFilter', { startDate: this.startDate, endDate: this.endDate });
            break;
          case '7days':
            this.showFilters = false;
            this.endDate = `${today.toISOString().split('T')[0]} 23:59:59`;
            today.setDate(today.getDate() - 7);
            this.startDate = `${today.toISOString().split('T')[0]} 00:00:00`;
            this.$emit('onFilter', { startDate: this.startDate, endDate: this.endDate });
            break;
          case '15days':
            this.showFilters = false;
            this.endDate = `${today.toISOString().split('T')[0]} 23:59:59`;
            today.setDate(today.getDate() - 15);
            this.startDate = `${today.toISOString().split('T')[0]} 00:00:00`;
            this.$emit('onFilter', { startDate: this.startDate, endDate: this.endDate });
            break;
          case '30days':
            this.showFilters = false;
            this.endDate = `${today.toISOString().split('T')[0]} 23:59:59`;
            today.setDate(today.getDate() - 30);
            this.startDate = `${today.toISOString().split('T')[0]} 00:00:00`;
            this.$emit('onFilter', { startDate: this.startDate, endDate: this.endDate });
            break;
          default:
            this.showFilters = false;
            break;
        }
      },
      setStartDate (date) {
        this.startDateFromPicker = `${date} 00:00:00`;
        this.startDate = `${date} 00:00:00`;
        this.showStartDatePicker = false;
        if (this.endDateFromPicker) {
          this.$emit('onFilter', { startDate: this.startDate, endDate: this.endDate });
        }
      },
      setEndDate (date) {
        this.endDateFromPicker = `${date} 23:59:59`;
        this.endDate = `${date} 23:59:59`;
        this.showStartDatePicker = false;
        if (this.startDateFromPicker) {
          this.$emit('onFilter', { startDate: this.startDate, endDate: this.endDate });
        }
        this.showEndDatePicker = false;
      },
      applyFilter () {
        this.$emit('applyFilters');
      },
    },
  };

</script>
<style>
  .selected-chip {
    border-color: var(--v-secondary-base) !important;
    color: #FFFFFF !important;
    background-color: var(--v-accent-base) !important;
  }
  .selected-chip span {
    color: var(--v-secondary-base) !important;
  }
  .card-footer {
    text-align: end;
  }
</style>
